var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-2"},[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#00a89d","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.load === 'true')?_c('b-spinner',{staticClass:"mr-1",attrs:{"variant":"primary"}}):_vm._e()],1),_c('tab-content',{attrs:{"title":_vm.$t('Admission'),"before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-3",attrs:{"cols":"12"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"BookOpenIcon"}}),_c('span',{staticClass:"mb-0 font-weight-bold h5"},[_vm._v(" "+_vm._s(_vm.$t("Reserveyourplace"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("DiplomaType"))+" ,"+_vm._s(_vm.$t("Specialty"))+" , "+_vm._s(_vm.$t("Level"))+" ")])],1),(_vm.lead.specialite_id == null)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DiplomaType'),"label-for":"parcour"}},[_c('validation-provider',{attrs:{"name":"parcour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":_vm.$t('ChooseDiplomaType'),"label":"nom","options":_vm.parcours},model:{value:(_vm.parcour),callback:function ($$v) {_vm.parcour=$$v},expression:"parcour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1016551668)})],1)],1):_vm._e(),(_vm.lead.specialite_id != null)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DiplomaType'),"label-for":"parcour"}},[_c('validation-provider',{attrs:{"name":"parcour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":"","value":_vm.speciality.parcour,"label":"nom","clearable":false,"placeholder":"Choisir Parcour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2087405726)})],1)],1):_vm._e(),(_vm.lead.specialite_id == null)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Specialty'),"label-for":"specialite"}},[_c('validation-provider',{attrs:{"name":"specialite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"nom","options":_vm.mySpecialites,"placeholder":"Choisir Spécialité"},model:{value:(_vm.specialite),callback:function ($$v) {_vm.specialite=$$v},expression:"specialite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1345822042)})],1)],1):_vm._e(),(_vm.lead.specialite_id != null)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Spécialité","label-for":"specia"}},[_c('validation-provider',{attrs:{"name":"specia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":"","value":_vm.speciality,"clearable":false,"label":"nom","options":_vm.mySpecialites,"placeholder":_vm.$t('ChooseSpecialty')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2138567437)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Level'),"label-for":"niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.ok,"clearable":false,"label":"Niveau","options":_vm.myNiveaux,"placeholder":_vm.$t('ChooseLevel')},model:{value:(_vm.lead.niveau),callback:function ($$v) {_vm.$set(_vm.lead, "niveau", $$v)},expression:"lead.niveau"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-3",attrs:{"cols":"12"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"mb-0 font-weight-bold h5"},[_vm._v(" "+_vm._s(_vm.$t("PersonalData"))+" ")])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Civility'),"label-for":"civilite"}},[_c('validation-provider',{attrs:{"name":"civilite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.ok,"clearable":false,"label":"Civilité","options":_vm.civilites,"placeholder":_vm.$t('Civility')},model:{value:(_vm.lead.civilite),callback:function ($$v) {_vm.$set(_vm.lead, "civilite", $$v)},expression:"lead.civilite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FirstName'),"label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nom","disabled":_vm.ok,"placeholder":_vm.$t('FirstName'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.nom),callback:function ($$v) {_vm.$set(_vm.lead, "nom", $$v)},expression:"lead.nom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LastName'),"label-for":"prenom"}},[_c('validation-provider',{attrs:{"name":"Prenom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"prenom","disabled":_vm.ok,"placeholder":_vm.$t('LastName'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.prenom),callback:function ($$v) {_vm.$set(_vm.lead, "prenom", $$v)},expression:"lead.prenom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CINPasseport'),"label-for":"cin"}},[_c('validation-provider',{attrs:{"name":"cin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cin","disabled":_vm.ok,"placeholder":_vm.$t('CINPasseport'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.cin),callback:function ($$v) {_vm.$set(_vm.lead, "cin", $$v)},expression:"lead.cin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Country'),"label-for":"pays"}},[_c('validation-provider',{attrs:{"name":"pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cin","disabled":_vm.ok,"placeholder":_vm.$t('Country'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.pays),callback:function ($$v) {_vm.$set(_vm.lead, "pays", $$v)},expression:"lead.pays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.$t("Date of Birth")))]),_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker","disabled":_vm.ok},model:{value:(_vm.lead.dateNaissance),callback:function ($$v) {_vm.$set(_vm.lead, "dateNaissance", $$v)},expression:"lead.dateNaissance"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Place of birth'),"label-for":"lieuNaissance"}},[_c('validation-provider',{attrs:{"name":"lieuNaissance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lieuNaissance","disabled":_vm.ok,"placeholder":_vm.$t('Place of birth'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.lieuNaissance),callback:function ($$v) {_vm.$set(_vm.lead, "lieuNaissance", $$v)},expression:"lead.lieuNaissance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Phone'),"label-for":"telephone"}},[_c('validation-provider',{attrs:{"name":"telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telephone","disabled":_vm.ok,"placeholder":_vm.$t('Phone'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.telephone),callback:function ($$v) {_vm.$set(_vm.lead, "telephone", $$v)},expression:"lead.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":"","placeholder":_vm.$t('Email'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.email),callback:function ($$v) {_vm.$set(_vm.lead, "email", $$v)},expression:"lead.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"adress"}},[_c('validation-provider',{attrs:{"name":"adress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"adress","disabled":_vm.ok,"placeholder":_vm.$t('Address'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.adress),callback:function ($$v) {_vm.$set(_vm.lead, "adress", $$v)},expression:"lead.adress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-3",attrs:{"cols":"12"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"BookmarkIcon"}}),_c('span',{staticClass:"mb-0 font-weight-bold h5"},[_vm._v(" "+_vm._s(_vm.$t("School curriculum"))+" ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Baccalaureate'),"label-for":"bac"}},[_c('validation-provider',{attrs:{"name":"bac","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.ok,"clearable":false,"label":"Baccalauréat","options":_vm.baccalaureats,"placeholder":_vm.$t('Baccalaureate')},model:{value:(_vm.lead.bac),callback:function ($$v) {_vm.$set(_vm.lead, "bac", $$v)},expression:"lead.bac"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleObt'),"label-for":"typeBac"}},[_c('validation-provider',{attrs:{"name":"titre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typeBac","disabled":_vm.ok,"placeholder":_vm.$t('TitleObt'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.typeBac),callback:function ($$v) {_vm.$set(_vm.lead, "typeBac", $$v)},expression:"lead.typeBac"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Year of graduation'),"label-for":"anneeBac"}},[_c('validation-provider',{attrs:{"name":"Prenom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"anneeBac","disabled":_vm.ok,"placeholder":_vm.$t('Year of graduation'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.anneeBac),callback:function ($$v) {_vm.$set(_vm.lead, "anneeBac", $$v)},expression:"lead.anneeBac"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Upload of the Bac diploma or equivalent'),"label-for":"baccalaureat"}},[_c('validation-provider',{attrs:{"name":"baccalaureat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"disabled":_vm.ok,"placeholder":_vm.$t('Upload of the Bac diploma or equivalent'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadDiplome),callback:function ($$v) {_vm.$set(_vm.lead, "uploadDiplome", $$v)},expression:"lead.uploadDiplome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Last Diploma other than the Bac'),"label-for":"diplome"}},[_c('validation-provider',{attrs:{"name":"diplome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.ok,"clearable":false,"label":"Dernier Diplôme autre que le Bac ","options":_vm.diplomes,"placeholder":_vm.$t('Last Diploma other than the Bac')},model:{value:(_vm.lead.diplome),callback:function ($$v) {_vm.$set(_vm.lead, "diplome", $$v)},expression:"lead.diplome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleObt'),"label-for":"typeDiplome"}},[_c('validation-provider',{attrs:{"name":"typeDiplome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typeDiplome","disabled":_vm.ok,"placeholder":_vm.$t('TitleObt'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.typeDiplome),callback:function ($$v) {_vm.$set(_vm.lead, "typeDiplome", $$v)},expression:"lead.typeDiplome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Year of graduation'),"label-for":"anneeDiplome"}},[_c('validation-provider',{attrs:{"name":"anneeDiplome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"anneeDiplome","disabled":_vm.ok,"placeholder":_vm.$t('Year of graduation'),"state":errors.length > 0 ? false : null},model:{value:(_vm.lead.anneeDiplome),callback:function ($$v) {_vm.$set(_vm.lead, "anneeDiplome", $$v)},expression:"lead.anneeDiplome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Upload Latest Diploma'),"label-for":"fileDiplome"}},[_c('validation-provider',{attrs:{"name":"fileDiplome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"disabled":_vm.ok,"placeholder":_vm.$t('Upload Latest Diploma'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadBac),callback:function ($$v) {_vm.$set(_vm.lead, "uploadBac", $$v)},expression:"lead.uploadBac"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('Pre-registration'),"before-change":_vm.admission}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-table',{attrs:{"responsive":"sm","items":_vm.items},scopedSlots:_vm._u([{key:"cell(Étiquette)",fn:function(data){return [_c('b-badge',{staticClass:"badge-glow",attrs:{"pill":"","variant":data.item.Étiquette.variant}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":data.item.Étiquette.icon}}),_c('span',[_vm._v(" "+_vm._s(data.item.Étiquette.status))])],1),(
                data.item.Tâche == 'Choix du parcours' ||
                  data.item.Task == 'Choice of class'
              )?_c('h5',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$t("DiplomaType"))+" : "+_vm._s(_vm.speciality.parcour.nom)+" ")]):_vm._e(),(
                data.item.Tâche == 'Choix du parcours' ||
                  data.item.Task == 'Choice of class'
              )?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("Specialty"))+" :"+_vm._s(_vm.speciality.nom)+" ")]):_vm._e(),(
                data.item.Tâche == 'Choix du parcours' ||
                  data.item.Task == 'Choice of class'
              )?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("Level"))+" : "+_vm._s(_vm.lead.niveau)+" ")]):_vm._e()]}}])})],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('SubscriptionRequest'),"before-change":_vm.inscription}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Dossier d'inscription : ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Remplir le formulaire a fin de continuer les étapes d'inscription")])]),(_vm.inscri == false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload copie du passeport ou CIN:","label-for":"copieCin"}},[_c('validation-provider',{attrs:{"name":"copieCin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadCin),callback:function ($$v) {_vm.$set(_vm.lead, "uploadCin", $$v)},expression:"lead.uploadCin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2229417982)})],1)],1):_vm._e(),(_vm.inscri)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload copie du passeport ou CIN:","label-for":"copieCin"}},[_c('validation-provider',{attrs:{"name":"copieCin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadCin),callback:function ($$v) {_vm.$set(_vm.lead, "uploadCin", $$v)},expression:"lead.uploadCin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1412656424)})],1)],1):_vm._e(),(_vm.inscri == false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Acte de naissance","label-for":"acteNaiss"}},[_c('validation-provider',{attrs:{"name":"acteNaiss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"disabled":_vm.inscri,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadActNaiss),callback:function ($$v) {_vm.$set(_vm.lead, "uploadActNaiss", $$v)},expression:"lead.uploadActNaiss"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3940453504)})],1)],1):_vm._e(),(_vm.inscri)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Acte de naissance","label-for":"acteNaiss"}},[_c('validation-provider',{attrs:{"name":"acteNaiss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadActNaiss),callback:function ($$v) {_vm.$set(_vm.lead, "uploadActNaiss", $$v)},expression:"lead.uploadActNaiss"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2230338332)})],1)],1):_vm._e(),(_vm.inscri == false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Relevés de Notes :","label-for":"releveNote"}},[_c('validation-provider',{attrs:{"name":"releveNote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadNote),callback:function ($$v) {_vm.$set(_vm.lead, "uploadNote", $$v)},expression:"lead.uploadNote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1765614602)})],1)],1):_vm._e(),(_vm.inscri)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Relevés de Notes :","label-for":"releveNote"}},[_c('validation-provider',{attrs:{"name":"releveNote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadNote),callback:function ($$v) {_vm.$set(_vm.lead, "uploadNote", $$v)},expression:"lead.uploadNote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1291770908)})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('PaymentandServices'),"before-change":_vm.paiement}},[_c('validation-observer',{ref:"payement",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Paiement scolarité: ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Veuillez saisir les informations de paiement")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Méthode","label-for":"methode"}},[_c('validation-provider',{attrs:{"name":"methode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.paier,"clearable":false,"label":"Méthode","options":_vm.methodes,"placeholder":"Choisir Méthode"},model:{value:(_vm.lead.methodePaiement),callback:function ($$v) {_vm.$set(_vm.lead, "methodePaiement", $$v)},expression:"lead.methodePaiement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Devise","label-for":"devise"}},[_c('validation-provider',{attrs:{"name":"devise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.paier,"clearable":false,"label":"Devise","options":_vm.devises,"placeholder":"Choisir Devise"},model:{value:(_vm.lead.devise),callback:function ($$v) {_vm.$set(_vm.lead, "devise", $$v)},expression:"lead.devise"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tranche","label-for":"tranche"}},[_c('validation-provider',{attrs:{"name":"tranche","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.paier,"clearable":false,"label":"Tranche","options":_vm.tranches,"placeholder":"Choisir tranche"},model:{value:(_vm.lead.tranche),callback:function ($$v) {_vm.$set(_vm.lead, "tranche", $$v)},expression:"lead.tranche"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.paier == false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pièce justificative","label-for":"uploadPaie"}},[_c('validation-provider',{attrs:{"name":"uploadPaie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadPaiement),callback:function ($$v) {_vm.$set(_vm.lead, "uploadPaiement", $$v)},expression:"lead.uploadPaiement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1898246581)})],1)],1):_vm._e(),(_vm.paier)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pièce justificative","label-for":"uploadPaieme"}},[_c('validation-provider',{attrs:{"name":"uploadPaieme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.lead.uploadPaiement),callback:function ($$v) {_vm.$set(_vm.lead, "uploadPaiement", $$v)},expression:"lead.uploadPaiement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2093532867)})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('Confirmation')}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-table',{attrs:{"responsive":"sm","items":_vm.confirmation},scopedSlots:_vm._u([{key:"cell(Étiquette)",fn:function(data){return [_c('b-badge',{staticClass:"badge-glow",attrs:{"pill":"","variant":data.item.Étiquette.variant}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":data.item.Étiquette.icon}}),_c('span',[_vm._v(" "+_vm._s(data.item.Étiquette.status))])],1),(data.item.Tâche == 'Choix du parcours')?_c('h5',{staticClass:"mt-1"},[_vm._v(" Parcour : "+_vm._s(_vm.speciality.parcour.nom)+" ")]):_vm._e(),(data.item.Tâche == 'Choix du parcours')?_c('h5',[_vm._v(" Spécialité :"+_vm._s(_vm.speciality.nom)+" ")]):_vm._e(),(data.item.Tâche == 'Choix du parcours')?_c('h5',[_vm._v(" Niveau : "+_vm._s(_vm.lead.niveau)+" ")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }